export const getters = {
    list: state => state.list,
    model: state => state.model,
    rules: state => state.rules,
    reports: state => state.reports,
    columns: state => state.columns,
    show_pagination: state => state.show_pagination,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    statues: state => state.statues,
    permissions: state => state.permissions,
    spess: state => state.spess
};