import Layout from '@/layouts'

const schedule = {
  path: '/schedulesCabinet',
  component: Layout,
  name: 'schedulesCabinet',
  redirect: '/schedulesCabinet/index',
  children: [{
    path: '/schedulesCabinet/index',
    component: () => import('@/views/schedulesCabinet/index'),
    name: 'schedulesCabinet.index',
    meta: {
      title: 'Расписания кабинет '
    }
  },
  {

    path: '/bunk/hospitalRooms/withbunk',
    component: () => import('@/views/schedulesCabinet/index'),
    name: 'hospitalRooms',
    meta: {
      title: ''
    }

  },
 ]
}

export default schedule