import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/patientTransactionHistorys',
  	  method: 'get',
      params
  	})
}

export function indexitems(params) {
  return request({
    url: `/patientTransactionHistorys/indexitems/${params.hospital_patient_id}`,
    method: 'GET',
    params
  });
}

export function returnBack(id) {
  return request({
    url: `/patientTransactionHistorys/returnBack/${id}`,
    method: 'GET',
  });
}

export function inventory(params) {
  return request({
    url: '/patientTransactionHistorys/inventory',
    method: 'get',
    params
  })
}
  
export function show(id) {
    return request({
      url: `/patientTransactionHistorys/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/patientTransactionHistorys',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/patientTransactionHistorys/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/patientTransactionHistorys/${id}`,
      method: 'delete',
    })
  }
